import { type ChangeEvent, type KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { useDebounce } from '~/hooks/use-debounce';

import styles from './styles.module.css';

import Required from './required';
import { InputWrapper, Note, Prefix, SpanLabel, StyledInput, Suffix } from './styled';
import classNames from 'classnames';

type LabelType = { label: string; overlayedLabel: true } | { label?: string | JSX.Element; overlayedLabel?: false };

type Props<T> = {
  updateValue: (value: T) => void;
  onEnter?: (value: T) => void;
  value: T;
  placeholder?: string;
  className?: string;
  note?: string;
  prefix?: string;
  suffix?: string;
  required?: boolean;
  large?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  pasteModifier?: (val: string) => string;
  min?: number;
  max?: number;
  type?: 'number' | 'string' | 'password';
} & LabelType;

export const InputBlock = <T extends number | string | undefined>({
  updateValue,
  pasteModifier,
  onEnter,
  autofocus,
  type,
  value,
  label,
  className,
  required,
  overlayedLabel,
  large,
  prefix,
  suffix,
  note,
  placeholder,
  disabled,
  min,
  max,
}: Props<T>) => {
  const [localValue, setLocalValue] = useState(value);
  const modifierKeyPressed = useRef(false);
  const lastUpdatedVal = useRef(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const [prefixRef, setPrefixRef] = useState<HTMLSpanElement | null>(null);
  const [suffixRef, setSuffixRef] = useState<HTMLSpanElement | null>(null);

  useEffect(() => {
    setLocalValue(value);
    lastUpdatedVal.current = value;
  }, [value]);

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus();
    }
  }, [autofocus]);

  const handleUpdateValue = (newVal: T) => {
    if (`${newVal}` !== `${lastUpdatedVal.current}`) {
      updateValue(newVal);
      lastUpdatedVal.current = newVal;
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdateValue(localValue);
      onEnter?.(localValue);
    }

    if (e.metaKey || e.ctrlKey) {
      modifierKeyPressed.current = true;
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!e.metaKey && !e.ctrlKey) {
      modifierKeyPressed.current = false;
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let val = event.currentTarget.value;

    if (modifierKeyPressed.current && pasteModifier) {
      val = pasteModifier(event.currentTarget.value);
    }
    setLocalValue(val as T);
  };

  const debouncedRequest = useDebounce(() => {
    handleUpdateValue(localValue);
  });

  const prefixWidth = useMemo(() => {
    return prefixRef?.getBoundingClientRect().width || 0;
  }, [prefixRef]);

  const suffixWidth = useMemo(() => {
    return suffixRef?.getBoundingClientRect().width || 0;
  }, [suffixRef]);

  return (
    <label
      className={classNames(styles.labelWrap, { [styles.isOverlayedLabel]: overlayedLabel, [styles.large]: large })}
    >
      {((label && !overlayedLabel) || (label && overlayedLabel && localValue !== undefined)) && (
        <SpanLabel isOverlayed={overlayedLabel}>
          {label}
          {required && <Required />}
        </SpanLabel>
      )}

      {note && <Note>{note}</Note>}
      <InputWrapper tag="span">
        {prefix && (
          <Prefix key={prefix} ref={setPrefixRef}>
            {prefix}
          </Prefix>
        )}
        <StyledInput
          style={{
            paddingLeft: prefixWidth ? `${prefixWidth + 12}px` : '12px',
            paddingRight: suffixWidth ? `${suffixWidth + 12}px` : '12px',
          }}
          className={classNames(className, { [styles.large]: large })}
          ref={inputRef}
          type={type || 'text'}
          placeholder={
            placeholder ? placeholder : overlayedLabel && !localValue ? `${label}${required ? ' *' : ''}` : ''
          }
          disabled={disabled}
          value={localValue}
          min={min}
          max={max}
          onBlur={() => handleUpdateValue(localValue)}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            debouncedRequest();
            changeHandler(event);
          }}
        />
        {suffix && (
          <Suffix key={suffix} ref={setSuffixRef}>
            {suffix}
          </Suffix>
        )}
      </InputWrapper>
    </label>
  );
};
